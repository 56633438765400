import createLucideIcon from '../createLucideIcon';

const Outdent = createLucideIcon('Outdent', [
  ['polyline', { points: '7 8 3 12 7 16', key: '2j60jr' }],
  ['line', { x1: '21', x2: '11', y1: '12', y2: '12', key: '1fxxak' }],
  ['line', { x1: '21', x2: '11', y1: '6', y2: '6', key: 'asgu94' }],
  ['line', { x1: '21', x2: '11', y1: '18', y2: '18', key: '13dsj7' }],
]);

export default Outdent;
