import createLucideIcon from '../createLucideIcon';

const MinusSquare = createLucideIcon('MinusSquare', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '8', x2: '16', y1: '12', y2: '12', key: '1jonct' }],
]);

export default MinusSquare;
