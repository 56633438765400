import createLucideIcon from '../createLucideIcon';

const CalendarRange = createLucideIcon('CalendarRange', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '4',
      rx: '2',
      ry: '2',
      key: 'eu3xkr',
    },
  ],
  ['line', { x1: '16', x2: '16', y1: '2', y2: '6', key: 'm3sa8f' }],
  ['line', { x1: '8', x2: '8', y1: '2', y2: '6', key: '18kwsl' }],
  ['line', { x1: '3', x2: '21', y1: '10', y2: '10', key: 'xt86sb' }],
  ['path', { d: 'M17 14h-6', key: 'bkmgh3' }],
  ['path', { d: 'M13 18H7', key: 'bb0bb7' }],
  ['path', { d: 'M7 14h.01', key: '1qa3f1' }],
  ['path', { d: 'M17 18h.01', key: '1bdyru' }],
]);

export default CalendarRange;
