import createLucideIcon from '../createLucideIcon';

const List = createLucideIcon('List', [
  ['line', { x1: '8', x2: '21', y1: '6', y2: '6', key: '7ey8pc' }],
  ['line', { x1: '8', x2: '21', y1: '12', y2: '12', key: 'rjfblc' }],
  ['line', { x1: '8', x2: '21', y1: '18', y2: '18', key: 'c3b1m8' }],
  ['line', { x1: '3', x2: '3.01', y1: '6', y2: '6', key: '1g7gq3' }],
  ['line', { x1: '3', x2: '3.01', y1: '12', y2: '12', key: '1pjlvk' }],
  ['line', { x1: '3', x2: '3.01', y1: '18', y2: '18', key: '28t2mc' }],
]);

export default List;
