import createLucideIcon from '../createLucideIcon';

const Table = createLucideIcon('Table', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '3', x2: '21', y1: '9', y2: '9', key: '1vqk6q' }],
  ['line', { x1: '3', x2: '21', y1: '15', y2: '15', key: 'o2sbyz' }],
  ['line', { x1: '12', x2: '12', y1: '3', y2: '21', key: '1efggb' }],
]);

export default Table;
