import createLucideIcon from '../createLucideIcon';

const ListOrdered = createLucideIcon('ListOrdered', [
  ['line', { x1: '10', x2: '21', y1: '6', y2: '6', key: '76qw6h' }],
  ['line', { x1: '10', x2: '21', y1: '12', y2: '12', key: '16nom4' }],
  ['line', { x1: '10', x2: '21', y1: '18', y2: '18', key: 'u3jurt' }],
  ['path', { d: 'M4 6h1v4', key: 'cnovpq' }],
  ['path', { d: 'M4 10h2', key: '16xx2s' }],
  ['path', { d: 'M6 18H4c0-1 2-2 2-3s-1-1.5-2-1', key: 'm9a95d' }],
]);

export default ListOrdered;
