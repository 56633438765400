import createLucideIcon from '../createLucideIcon';

const Repeat = createLucideIcon('Repeat', [
  ['path', { d: 'm17 2 4 4-4 4', key: 'nntrym' }],
  ['path', { d: 'M3 11v-1a4 4 0 0 1 4-4h14', key: '84bu3i' }],
  ['path', { d: 'm7 22-4-4 4-4', key: '1wqhfi' }],
  ['path', { d: 'M21 13v1a4 4 0 0 1-4 4H3', key: '1rx37r' }],
]);

export default Repeat;
