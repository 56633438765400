import createLucideIcon from '../createLucideIcon';

const Columns = createLucideIcon('Columns', [
  [
    'rect',
    {
      width: '18',
      height: '18',
      x: '3',
      y: '3',
      rx: '2',
      ry: '2',
      key: '1m3agn',
    },
  ],
  ['line', { x1: '12', x2: '12', y1: '3', y2: '21', key: '1efggb' }],
]);

export default Columns;
