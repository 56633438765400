import createLucideIcon from '../createLucideIcon';

const Indent = createLucideIcon('Indent', [
  ['polyline', { points: '3 8 7 12 3 16', key: 'f3rxhf' }],
  ['line', { x1: '21', x2: '11', y1: '12', y2: '12', key: '1fxxak' }],
  ['line', { x1: '21', x2: '11', y1: '6', y2: '6', key: 'asgu94' }],
  ['line', { x1: '21', x2: '11', y1: '18', y2: '18', key: '13dsj7' }],
]);

export default Indent;
