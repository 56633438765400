import createLucideIcon from '../createLucideIcon';

const AlignHorizontalJustifyStart = createLucideIcon(
  'AlignHorizontalJustifyStart',
  [
    [
      'rect',
      { width: '6', height: '14', x: '6', y: '5', rx: '2', key: 'hsirpf' },
    ],
    [
      'rect',
      { width: '6', height: '10', x: '16', y: '7', rx: '2', key: '13zkjt' },
    ],
    ['path', { d: 'M2 2v20', key: '1ivd8o' }],
  ],
);

export default AlignHorizontalJustifyStart;
