import createLucideIcon from '../createLucideIcon';

const Heading2 = createLucideIcon('Heading2', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  ['path', { d: 'M21 18h-4c0-4 4-3 4-6 0-1.5-2-2.5-4-1', key: '9jr5yi' }],
]);

export default Heading2;
