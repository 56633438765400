import createLucideIcon from '../createLucideIcon';

const Heading3 = createLucideIcon('Heading3', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  [
    'path',
    { d: 'M17.5 10.5c1.7-1 3.5 0 3.5 1.5a2 2 0 0 1-2 2', key: '68ncm8' },
  ],
  ['path', { d: 'M17 17.5c2 1.5 4 .3 4-1.5a2 2 0 0 0-2-2', key: '1ejuhz' }],
]);

export default Heading3;
