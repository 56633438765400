import createLucideIcon from '../createLucideIcon';

const AlignVerticalJustifyStart = createLucideIcon(
  'AlignVerticalJustifyStart',
  [
    [
      'rect',
      { width: '14', height: '6', x: '5', y: '16', rx: '2', key: '1i8z2d' },
    ],
    [
      'rect',
      { width: '10', height: '6', x: '7', y: '6', rx: '2', key: '13squh' },
    ],
    ['path', { d: 'M2 2h20', key: '1ennik' }],
  ],
);

export default AlignVerticalJustifyStart;
