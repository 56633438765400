import createLucideIcon from '../createLucideIcon';

const ListChecks = createLucideIcon('ListChecks', [
  ['line', { x1: '10', x2: '21', y1: '6', y2: '6', key: '76qw6h' }],
  ['line', { x1: '10', x2: '21', y1: '12', y2: '12', key: '16nom4' }],
  ['line', { x1: '10', x2: '21', y1: '18', y2: '18', key: 'u3jurt' }],
  ['polyline', { points: '3 6 4 7 6 5', key: 'ectua5' }],
  ['polyline', { points: '3 12 4 13 6 11', key: 'gtbhyw' }],
  ['polyline', { points: '3 18 4 19 6 17', key: 'qzp18e' }],
]);

export default ListChecks;
